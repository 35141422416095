<template>
    <div class="row" style="margin-top: 200px;">
        <div class="col-lg-6 offset-lg-3 col-xs-12 col-sm-12">
            <div class="card">
  <div class="card-body">
    <h5 class="card-title">Cap Transfer</h5>
    <small>Aplikasi Cap Transfer by AdminUsaha.com</small>
    <div class="form-floating">
        <div class="mb-3">
  <label for="files" class="form-label">Upload screenshot bukti transfer</label>
  <input @change="upload" class="form-control" type="file" id="files" name="files[]" multiple>

</div>
</div>

<button v-show="!isLoading" @click="doUpload" type="button" class="btn btn-primary">
                Convert
            </button>

            <h6 class="text-danger" v-show="isLoading">Uploading...</h6>

  </div>
</div>
        </div>
    </div>
</template>

<script>
import { store } from '../store.js'

export default {
    mounted() {
        //
    }, // end moutned
    data() {
        return {
            files: [],
            isLoading: false,
        }
    }, // end data

    methods: {
        upload(event) {
            this.files = event.target.files;
        }, // end upload methods

        doUpload() {
            this.isLoading = true;
            const formData = new FormData();

            for (let i = 0; i < this.files.length; i++) {
                formData.append('files[]', this.files[i]);
            } // end for files

            window.axios.post('/upload', formData)
            .then(response => {
        // Tanggapan sukses
        console.log(response.data);
        store.response = response.data;
        this.isLoading = false;

        this.$router.push({name: 'result'})
      })
      .catch(error => {
        // Tanggapan error
        console.error(error);
        this.isLoading = false;
      });
        }, // end do upload method
    }, // end methods
}
</script>