<template>
  <nav class="navbar navbar-light bg-light">
  <div class="container">
    <router-link class="navbar-brand" to="/">
      AdminUsaha.com
    </router-link>
  </div>
</nav>
  
  <router-view></router-view>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  // components: {
  //   HelloWorld
  // },
  mounted() {
    console.log('halo')
  }, // end mounted
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
