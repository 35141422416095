// Page Components
import HomePage from "./pages/HomePage";
import UploadPage from "./pages/UploadPage";
import ResultPage from "./pages/ResultPage";

export default [
    { path: "/", component: HomePage, name: 'home' },
    { path: "/upload", component: UploadPage, name: 'upload' },
    { path: "/result", component: ResultPage, name: 'result' },
];
