<template>
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-8">
                            <h5 class="card-title text-start">Result</h5>
                        </div>

                        <div class="col-lg-4">
                            <a href="#" @click="download($event)" target="_blank"
                                class="btn btn-primary btn-sm float-end"><i class="bi bi-download"></i> Download</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!-- end row title -->

    <!-- start row resulst -->
    <div class="row">
        <div class="col-lg-12 col-sm-12 col-xs-12">
            <!-- start looping -->
            <div class="row">
                <div v-for="(result, index) in results" :key="index" class="col-lg-4 col-sm-12 col-xs-12 col-md-6">
                    <!-- start card -->
                    <div class="card">
                        <div class="card-body">
                            <!-- start row image dan list -->
                            <div class="row">
                                <div class="col-lg-6 col-sm-6 col-cs-6">
                                    <img :src="'https://aisisten.com/api/captransfer/' + result[6]" class="img-fluid"
                                        alt="">
                                </div>

                                <div class="col-lg-6 col-sm-6 col-cs-6">
                                    <a href="#" @click="showDeleteModal(index, $event)" class="float-end"><i
                                            class="bi bi-trash-fill"></i></a>

                                    <a href="#" @click="showEditModal(index, $event)" class="float-end me-2"><i
                                            class="bi bi-pencil-square"></i></a>

                                    <ul type="none" style="margin-top: 50px">
                                        <li v-text="result[0]"></li>
                                        <li v-text="result[1]"></li>
                                        <li v-text="result[2]"></li>
                                        <li v-text="result[3]"></li>
                                        <li v-text="result[4]"></li>
                                        <li v-text="result[5]"></li>
                                    </ul>
                                </div>
                            </div>
                            <!-- end row image dan list -->
                        </div>
                    </div>
                    <!-- end card -->
                </div>
            </div>
            <!-- end looping -->
        </div>
        <!-- end col lg 10 -->
    </div>
    <!-- end row resulst -->

    <div class="modal fade" id="deleteModalToggle" aria-hidden="true" aria-labelledby="deleteModalToggleLabel"
        tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="deleteModalToggleLabel">Delete Item</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Apakah Anda yakin ingin menghapus item berikut:</p>


                    <!-- start row image dan list -->
                    <div class="row" v-if="activeDeleteModal != null">
                        <div class="col-lg-6 col-sm-6 col-cs-6">
                            <img :src="'https://aisisten.com/api/captransfer/' + results[activeDeleteModal][6]"
                                class="img-fluid" alt="">
                        </div>

                        <div class="col-lg-6 col-sm-6 col-cs-6">
                            <ul type="none" style="margin-top: 50px">
                                <li v-text="results[activeDeleteModal][0]"></li>
                                <li v-text="results[activeDeleteModal][1]"></li>
                                <li v-text="results[activeDeleteModal][2]"></li>
                                <li v-text="results[activeDeleteModal][3]"></li>
                                <li v-text="results[activeDeleteModal][4]"></li>
                                <li v-text="results[activeDeleteModal][5]"></li>
                            </ul>
                        </div>
                    </div>
                    <!-- end row image dan list -->

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>

                    <button type="button" class="btn btn-primary" @click="confirmDeleteModal()">Confirm</button>
                </div>
            </div>
        </div>
    </div>

    <!-- edit modal -->
    <div class="modal fade" id="editModalToggle" aria-hidden="true" aria-labelledby="deleteModalToggleLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="editModalToggleLabel">Edit Item</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- start row image dan list -->
                    <div class="row" v-if="activeEditModal != null">
                        <div class="col-lg-6 col-sm-6 col-cs-6">
                            <img :src="'https://aisisten.com/api/captransfer/' + results[activeEditModal][6]"
                                class="img-fluid" alt="">
                        </div>

                        <div class="col-lg-6 col-sm-6 col-cs-6">
                            <div id="editForm" style="margin-top: 50px">
                                <div class="form-floating mb-2">
                                    <input type="text" class="form-control   form-control-sm" id="status" v-model="editedResult[0]"
                                        placeholder="Status*" name="status">

                                    <label for="status">Status</label>

                                </div>

                                <div class="form-floating mb-2">
                                    <input type="text" class="form-control   form-control-sm" id="date" v-model="editedResult[1]"
                                        placeholder="Tanggal Tansaksi*" name="date">

                                    <label for="date">Tanggal Transaksi</label>

                                </div>

                                <div class="form-floating mb-2">
                                    <input type="text" class="form-control   form-control-sm" id="to" v-model="editedResult[2]"
                                        placeholder="Nomor Rekening*" name="to">

                                    <label for="to">Nomor Rekening</label>

                                </div>

                                <div class="form-floating mb-2">
                                    <input type="text" class="form-control   form-control-sm" id="name" v-model="editedResult[3]"
                                        placeholder="Nama Pemilik Rekening*" name="name">

                                    <label for="name">Nama Pemilik Rekening</label>

                                </div>

                                <div class="form-floating mb-2">
                                    <input type="text" class="form-control   form-control-sm" id="amount" v-model="editedResult[4]"
                                        placeholder="Nominal*" name="amount">

                                    <label for="amount">Nominal</label>

                                </div>

                                <div class="form-floating mb-2">
                                    <input type="text" class="form-control   form-control-sm" id="note" v-model="editedResult[5]"
                                        placeholder="Berita*" name="note">

                                    <label for="note">Berita</label>

                                </div>

                                
                            </div>
                            <!-- end editForm -->
                        </div>
                    </div>
                    <div class="modal fade" id="deleteModalToggle" aria-hidden="true"
                        aria-labelledby="deleteModalToggleLabel" tabindex="-1">
                        <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="deleteModalToggleLabel">Delete Item</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal"
                                        aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <p>Apakah Anda yakin ingin menghapus item berikut:</p>


                                    <!-- start row image dan list -->
                                    <div class="row" v-if="activeDeleteModal != null">
                                        <div class="col-lg-6 col-sm-6 col-cs-6">
                                            <img :src="'https://aisisten.com/api/captransfer/' + results[activeDeleteModal][6]"
                                                class="img-fluid" alt="">
                                        </div>

                                        <div class="col-lg-6 col-sm-6 col-cs-6">
                                            <ul type="none" style="margin-top: 50px">
                                                <li v-text="results[activeDeleteModal][0]"></li>
                                                <li v-text="results[activeDeleteModal][1]"></li>
                                                <li v-text="results[activeDeleteModal][2]"></li>
                                                <li v-text="results[activeDeleteModal][3]"></li>
                                                <li v-text="results[activeDeleteModal][4]"></li>
                                                <li v-text="results[activeDeleteModal][5]"></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- end row image dan list -->

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>

                                    <button type="button" class="btn btn-primary"
                                        @click="confirmDeleteModal()">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row image dan list -->

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>

                    <button type="button" class="btn btn-primary" @click="confirmEditModal()">Confirm</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '../store.js'

export default {
    mounted() {
        console.log('ini sudah di result page')
        console.log(store.response.results)
        this.results = store.response.results
        this.sessionId = store.response.session_id

    }, // end moutned
    data() {
        return {
            results: [[]],
            sessionId: null,
            activeDeleteModal: null,
            activeEditModal: null,
            isPreparingDownload: false,
            editedResult: [],
        }
    }, // end data
    methods: {
        showDeleteModal(key, event) {
            event.preventDefault()
            this.activeDeleteModal = key;

            window.$('#deleteModalToggle').modal('show')
        }, // end showDeleteModal methods

        showEditModal(key, event) {
            event.preventDefault()
            this.activeEditModal = key;

            this.editedResult = this.results[key]

            window.$('#editModalToggle').modal('show')
        }, // end showEditModal methods

        confirmDeleteModal() {
            window.$('#deleteModalToggle').modal('hide')

            this.results.splice(this.activeDeleteModal, 1)

            this.activeDeleteModal = null
        }, // end confirmDeleteModal methods

        confirmEditModal() {
            window.$('#editModalToggle').modal('hide')

            this.results[this.activeEditModal] = this.editedResult

            this.activeEditModal = null
        }, // end confirmEditModal methods

        download(event) {
            event.preventDefault()

            this.isPreparingDownload = true;

            window.axios.post('/prepare-download', {
                "session_id": this.sessionId,
                "results": this.results
            })
                .then(response => {
                    // Tanggapan sukses
                    console.log(response.data);
                    let filename = response.data["filename"];
                    this.isPreparingDownload = false;

                    window.open('https://aisisten.com/api/captransfer/download/' + filename, '_blank');
                })
                .catch(error => {
                    // Tanggapan error
                    console.error(error);
                    this.isPreparingDownload = false;
                });
        }, // end download methods
    }, // end methods
}
</script>