export default {
    created() {
        //
    }, // end created
    data() {
        return {
            //
        }
    }, // end data
    computed: {
        //
    }, // end computed
    methods: {
        //
    }, // end methods
}