<template>
    <div class="row" style="margin-top: 200px;">
        <div class="col-lg-6 offset-lg-3 col-xs-12 col-sm-12">
            
            <div class="card">
  <div class="card-body">
    <h5 class="card-title">Cap Transfer</h5>
    <small>Aplikasi Cap Transfer by AdminUsaha.com</small>
    <p class="card-text">Cap Transfer dapat digunakan untuk mengekstrak data transaksi dari file gambar screenshot bukti transfer bank.</p>

    <router-link to="upload" class="btn btn-primary">
                Start
            </router-link>
  </div>
</div>
        </div>
    </div>
</template>

<script>
export default {
    mounted() {
        console.log('ini home page ya.')
    }, // end moutned
}
</script>